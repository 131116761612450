
import { defineComponent } from 'vue'

import ComplaintForm from '@/modules/PretensionsModule/components/ComplaintForm/index.vue'
import { useTitle } from 'vue-composable'

export default defineComponent({
    components: {
        ComplaintForm,
    },
    setup() {
        useTitle('Создание претензии')
    },
})
