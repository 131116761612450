
import { defineComponent } from 'vue'

// Components
import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import STextArea from '@/common/components/STextArea/index.vue'
import SFile from '@/common/components/SFile/index.vue'

// composable
import useComplaint from '../../composable/useComplaint'

export default defineComponent({
    components: {
        's-input': SInput,
        's-button': SButton,
        's-textarea': STextArea,
        's-file': SFile,
    },
    setup() {
        const { form_state, complaint_state, form_state$, createComplaint } = useComplaint()

        return {
            form_state,
            complaint_state,
            //
            form_state$,
            //
            createComplaint,
        }
    },
})
